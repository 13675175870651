import type { AppConfig } from "~types/api/config.types";
import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useState } from "react";

type AppContextType = {
  config: AppConfig;
  setConfig: Dispatch<SetStateAction<AppConfig>>;
};

const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<AppConfig>({
    applicationWorkSiteId: null,
  });

  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within a AppContextProvider");
  }
  return context;
};
